<template>
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ title }}</h4>
                </div>
                <div class="card-body">
                    <role-form :role="role">
                        <template #buttons="{save}">
                            <callback-button
                                class="ms-2"
                                :method="save"
                                :callback="back"
                            ></callback-button>
                        </template>
                    </role-form>
                </div>
            </div>
        </div>
    </div>
    <!-- end row -->
</template>

<script>
import {mapGetters} from "vuex";
import RoleForm from "../../../components/forms/role-form.vue";
import CallbackButton from "../../../components/elements/callback-button.vue";

export default {
    components: {CallbackButton, RoleForm},

    computed: {
        ...mapGetters({
            role: 'role/item',
            roleCategories: 'roleCategory/all'
        }),

        title: function () {
            return this.role.id ? this.$tc('authorization::roles.role', 2).ucFirst() + ' / ' + this.role.category_name + ' / ' + this.role.authority : this.$t('base.create_new_item', {item: this.$tc('authorization::roles.role', 1)}).ucFirst()
        },

        link: function () {
            return {path: this.$t('routes.' + '/authorization/roles')}
        }
    },

    methods: {
        back: function () {
            this.$router.push(this.link)
        },
    },

    watch: {
        title: function (value) {
            this.$store.dispatch('app/title', {key: value})
        },
    },

    mounted() {
        if (!this.roleCategories || !this.roleCategories.length) {
            this.$store.dispatch('roleCategory/all');
        }
        if (this.$route.params.id) {
            this.$store.dispatch('role/show', this.$route.params.id)
        }
    },

    unmounted() {
        this.$store.dispatch('role/clearItem')
        this.$store.dispatch('app/title', '')
    }

}
</script>

<style scoped>

</style>
