<script>
import {mapGetters} from "vuex";

export default {
    props: [
        'modelValue',
        'state',
        'error',
        'placeholder'
    ],

    computed: {
        ...mapGetters({
            'translations': 'trans/keys'
        }),

        model: {
            get: function () {
                return this.modelValue
            },
            set: function (value) {
                this.$emit('update:modelValue', value)
            },
        },
    },
}
</script>

<template>
    <select
            id="translation-key"
            name="translation_key"
            v-model="model"
            class="form-select"
            :class="{'border-danger': state === false}"
    >
        <option :value="typeof model === 'undefined' ? undefined : null">{{ placeholder }}</option>
        <option v-for="(translation, key) in translations" :key="key" :value="translation">{{ translation }}</option>
    </select>
    <div class="invalid-feedback" :class="{'d-block': state === false}">{{ error }}</div>
</template>

<style scoped>

</style>
