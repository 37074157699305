<template>
    <form>
        <b-overlay :show="loading" class="p-1" rounded>
            <div class="row mb-4">
                <div class="col-lg-4">

                    <label
                        :class="{'text-danger': states.role_category_id === false}"
                        class="form-label"
                        for="phone"
                    >{{ $t('authorization::roles.columns.role_category_id').ucFirst() }}</label
                    >
                    <input
                        id="users"
                        v-model="model.role_category_id"
                        :class="{'border-danger': states.role_category_id === false}"
                        :placeholder="$t('authorization::roles.placeholders.role_category_id').ucFirst()"
                        class="form-control"
                        list="category-list"
                        type="text"
                    />
                    <div :class="{'d-block': states.role_category_id === false}" class="invalid-feedback">
                        {{ errors.role_category_id }}
                    </div>
                    <datalist id="category-list">
                        <option v-for="(role, key) in roleCategories" :key="key" :value="role.id">{{
                                role.name
                            }}
                        </option>
                    </datalist>
                </div>

                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.authority === false}"
                        class="form-label"
                        for="authority"
                    >{{ $t('authorization::roles.columns.authority').ucFirst() }}</label
                    >
                    <input
                        id="authority"
                        v-model="model.authority"
                        :class="{'border-danger': states.authority === false}"
                        :placeholder="$t('authorization::roles.placeholders.authority').ucFirst()"
                        class="form-control"
                        name="authority"
                        type="text"
                    />
                    <div :class="{'d-block': states.authority === false}" class="invalid-feedback">{{
                            errors.authority
                        }}
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.translation_key === false}"
                        class="col-form-label col-lg-2"
                        for="translation_key"
                    >{{ $t('authorization::roles.columns.translation_key').ucFirst() }}</label
                    >
                    <div class="col-lg-10">
                        <translation-input
                            v-model="model.translation_key"
                            :error="errors.translation_key"
                            :placeholder="$t('authorization::roles.placeholders.translation_key', model.id ? {attribute: model.name} : {}).ucFirst()"
                            :state="states.translation_key"
                        ></translation-input>
                    </div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.alias === false}"
                        class="form-label"
                        for="alias"
                    >{{ $t('authorization::roles.columns.alias').ucFirst() }}</label
                    >
                    <input
                        id="alias"
                        v-model="model.alias"
                        :class="{'border-danger': states.alias === false}"
                        :placeholder="$t('authorization::roles.placeholders.alias').ucFirst()"
                        class="form-control"
                        name="alias"
                        type="text"
                    />
                    <div :class="{'d-block': states.alias === false}" class="invalid-feedback">{{ errors.alias }}</div>
                </div>
                <div class="col-lg-4">
                    <label
                        :class="{'text-danger': states.description_translation_key === false}"
                        class="form-label"
                        for="description_translation_key"
                    >{{ $t('authorization::roles.columns.description_translation_key').ucFirst() }}</label
                    >
                    <translation-input
                        v-model="model.description_translation_key"
                        :error="errors.description_translation_key"
                        :placeholder="$t('authorization::roles.placeholders.description_translation_key', model.id ? {attribute: model.description} : {}).ucFirst()"
                        :state="states.description_translation_key"
                    ></translation-input>
                </div>
                <div class="col-lg-12">
                    <label
                        :class="{'text-danger': states.description === false}"
                        class="form-label"
                        for="description"
                    >{{ $t('authorization::roles.columns.description').ucFirst() }}</label
                    >
                    <textarea
                        id="description"
                        v-model="model.description"
                        :class="{'border-danger': states.description === false}"
                        :placeholder="$t('authorization::roles.placeholders.description').ucFirst()"
                        class="form-control"
                        rows="3"
                    ></textarea>
                    <div :class="{'d-block': states.description === false}" class="invalid-feedback">
                        {{ errors.description }}
                    </div>
                </div>
            </div>
            <div class="row justify-content-end">
                <div class="col-lg-12">
                    <button class="btn btn-primary" type="submit" @click.prevent="save">
                        {{ btnText }}
                    </button>
                    <slot name="buttons" :save="save"></slot>
                </div>
            </div>
        </b-overlay>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import TranslationInput from "../elements/translation-input.vue";

export default {
    name: "RoleForm",

    emits: ['saved'],

    components: {TranslationInput},

    props: {
        role: {
            type: Object,
            required: true
        }
    },

    computed: {
        ...mapGetters({
            'loading': 'role/loading',
            'states': 'role/states',
            'errors': 'role/errors',
            'roleCategories': 'roleCategory/all'
        }),

        btnText: function () {
            return this.model.id ? this.$t('base.save').ucFirst() : this.$t('base.create').ucFirst()
        },
    },

    data: function () {
        return {
            model: JSON.parse(JSON.stringify(this.role)),
        }
    },

    methods: {
        save: function () {
            const method = this.model.id ? 'update' : 'create'

            return this.$store.dispatch(`role/${method}`, this.model).then(saved => {
                this.model = JSON.parse(JSON.stringify(saved))
                this.$emit('saved')
                if (method === 'create') {
                    this.$router.push(this.$t('routes.' + '/authorization/roles/:id(\\d+)', {id: this.model.id}).rmValidation())
                }
            })
        },
    },

    watch: {
        role: function () {
            this.model = JSON.parse(JSON.stringify(this.role))
        },
    },

    created() {
        // console.log(this.role)
    }
}
</script>

<style scoped>

</style>
